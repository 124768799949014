.container{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding: 5px;
    border-radius: 3px;
}

.TitleContainer{
    padding: 5px;
}

.Title{
    font-weight: bold;
}

.TextContainer{
    padding: 5px;
}

.TableContainer{
    padding: 10px;
}