.UserManagementContainer{
    /* -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75); */
    display: flex;
    /* width:80vw; */
    width: calc(100vw - 85px);
    flex-direction: column;
    gap:10px; 
    overflow-x: hidden;
}

.react-multi-carousel-item {
    width: auto !important; /* Override width */
    padding: 0 10px !important; /* Apply padding */
  }
@media screen and (max-width: 1023px) {
    .UserManagementContainer{
        display: flex;
        width: calc(100vw);
        flex-direction: column;
        gap:10px; 
    }
}
.UserManagementCarousel{
    margin-left: 4px;
    background-color: white;
    display: flex;
    flex-direction: row;
    min-height:50px;
    justify-content: center;
    align-items: center; 
    cursor: pointer;
    margin-right: 4px;
    padding: 5px 10px;
    /* -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75); */
    gap:10px;
    border-radius: 5px;
    z-index: 999;
}

.UserManagementCarouselLabelWrapperOne{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    width:100%;
    border-bottom: 3px solid #EF6C1A;
    cursor: pointer;   
}
.UserManagementCarouselLabelWrapperTwo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    width:100%;
    cursor: pointer;
    border-bottom: 3px solid #C2C2C2;
    transition: 0.3s ease-in-out;
 
}
.UserManagementCarouselLabelOne{
    color:#EF6C1A;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    font-size: 18px;
  }



.UserManagementCarouselLabelTwo{
    color:#C2C2C2;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    transition: 0.3s ease-in-out;
}