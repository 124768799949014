.ZoneMgtContainer{
    .MuiAutocomplete-popupIndicator,.css-uge3vf{
        color:black !important;
    }
    
    .MuiAutocomplete-input{
        color:black !important;
    }
    
    .MuiAutocomplete-clearIndicator{
        color:black !important; 
    }

    .MuiInputLabel-root{
        color:black !important;
    }

    .MuiLoadingButton-root{
        background-color: #731D3A !important;
    }
}