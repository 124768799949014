.RouteMgtBottomContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
  
        margin-top: 10px;
}

/*   background-color: white;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        border-radius: 5px; */


.RouteMgtBottomUpdateContainer{

}

.RouteMgtBottomAddContainer{
  
}

