.react-multi-carousel-item {
  width: auto !important; /* Override width */
  padding: 0 2px !important; /* Apply padding */
}
.MuiMenu-list{
  display: flex !important;
  flex-direction: column !important;
  padding: 4px !important;
}

.MuiButton-root{
  color: white !important;
  border: 2px solid #EF6C1A !important;
  background-color: #EF6C1A !important;
  height: 30px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
}
.MuiButton-root:hover {
  background-color: #EF6C1A !important;
  color: white !important;
  border: 2px solid #EF6C1A !important;
}