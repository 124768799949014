.RouteMgtTopContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: white;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        border-radius: 5px;
}

.RouteMgtTopWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.RouteMgtZoneContainer{
    flex: 1;
    display: flex;
    flex-direction: row;
    padding:5px 10px;
    padding-top: 25px;
    /* justify-content: space-between; */
}

.RouteMgtZoneContainerTitle{
     padding:0px 10px;
     font-weight: 600;
}

.RouteMgtAddContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    padding:5px 10px;
    padding-top: 15px;
    padding-right: 20px;
}

.RouteMgtBottomWrapper{
    padding: 5px 10px
}