.AddRouteMgt{
    background-color: white;
-webkit-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
    border-radius: 5px; 
    padding: 10px;
    margin-top: 10px;
}

.MapContainer{
    padding: 20px;
}

.Unassigned{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0px;
}

.Unassigned label{
    font-weight: bold;
}

.AddRouteMgtTopWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.EditContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.EditWrapper{
    background-color: white;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        box-shadow: 0px 4px 8px -4px rgba(0,0,0,0.75);
        border-radius: 100%; 
        padding: 10px;  
        margin-right: 10px;
        cursor: pointer;
}

.AddRouteMgtTopWrapperTitle{
    font-weight: 600;
}

.AddRouteMgtBottomWrapper{
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: 20px;
    gap:15px;
}

.AddRouteMgtOneSection{
    flex:1;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:15px;
}

.AddRouteMgtOneSectionlist{
    display: flex;
    flex-direction: column;
    gap:10px;
   
    
}

.AddRouteMgtOneSectionLabelContainer{
    flex:0.5;
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
}

.AddRouteMgtOneSectionTextFieldContainer{
    flex:1;
    display: flex;
    /* width: 15rem; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    flex-direction: column;
}

.AddRouteMgtOneSectionTextFieldContainerl{
    flex:1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}

.AddRouteMgtOneSectionLabel{
    font-size: 14px;
    font-weight: 600;
}

.ButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap:10px;
    margin-top: 10px;
}

.error{
    color: red;
    font-size: 12px;
}

.SecondContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding:15px 10px;
}

.BulkSwitchAddRouteMgtOneSectionlist{
    flex:0.2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BulkSwitchAddRouteMgtOneSectionlist{
    display: flex;
    flex-direction: row;
}
.SecondAddRouteMgtOneSectionlist{
    flex:1;
    display: flex;
    flex-direction: row;
}

.SecondAddRouteMgtOneSectionLabelContainer{
    flex:0.11;
    display: flex;
    justify-content: flex-end;
}

.SecondAddRouteMgtOneSectionTextFieldContainer{
    flex:1;
    padding: 0px 10px;
}

.BulkAddRouteMgtOneSectionLabelContainer{
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.BulkAddRouteMgtOneSectionTextFieldContainerl{
    flex:1;
    margin-left: 5px;
}