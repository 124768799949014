.map-Marker {
    margin-top: -30px;
    background-color: white;
    border-radius: 5px;
    font-weight: 600;
    word-break: break-all;
  }
  .poly-Marker{
    background-color: rgb(240, 240, 240, 0.6);
    margin-top: -30px;
    white-space:pre;
    border-radius: 5px;
    padding: 4px;
    word-break: break-all;
    display: flex;
    gap: 10px;
  }
  .cluster-label {
    color: white !important;
  }
  .MuiTabs-root {
    
    min-height: 30px !important;
    
}