/* styles.css */
.textField {
  width: 240px;
}


@media (min-width: 1400px) {
  .textField {
    width: 16vw;
  }
}


@media (max-width: 1400px) {
  .textField {
    width: 15vw;
  }
}

/* @media (max-width: 1300px) {
  .textField {
    width: 240px;
  }
}

@media (max-width: 1200px) {
  .textField {
    width: 240px;
  }
} */

@media (max-width: 992px) {
  .textField {
    width: 160px;
  }
}

/* @media (max-width: 768px) {
  .textField {
    width: 120px;
  }
}

@media (max-width: 576px) {
  .textField {
    width: 100px;
  }
} */
