.RootContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    background-color: white;
    justify-content: flex-start;
    border-radius: 5px;
}

.Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-top: 0px;
}

.TopContainer {
    
display: grid;
grid-template-columns:  1fr 1fr 1fr ;
gap:10px;

    padding: 45px 10px;
    border-radius: 5px;
    height: 100%;
}

.List {
    flex: 1;
    display: flex;
    flex-direction: row;

}

.AddList {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: -20px;
}

.BulkList {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    background-color: white;
    padding: 30px 10px;
    border-radius: 5px;
    margin-top: 0px;

}

.BulkSubList {

    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.TitleContainer {
    flex: 0.2;
    padding: 5px;
}

.TitleContainer>label {
    font-weight: 600;
}

.TextContainer {
    flex: 1;
}

.SelecText {
    font-size: 10px;
}

.BulkInput {
    display: none;
}

.BulkUploadButton {
    color: black;
    background-color: #cccccc !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
}

.BulkTitle {
    font-weight: 600;
    font-size: 13px;
}

.UploadContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.fileContainer {
    background-color: #ccf5ff;
    padding: 5px 10px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileButton {
    border-radius: 100%;
    background: white;
    border: none;
    margin-left: 10px;
    cursor: pointer;
}

.fileButtonIcon {
    width: 15px !important;
    height: 20px !important;
}

.TopLabel{
    font-size: 13px;
    min-width: 70px;
}