.container{
    flex-wrap: wrap;
    width: 90%;
}
.InputContainer{
 border:2px solid #719ECE;
 border-radius: 5px;
 padding: 8px;
 display: flex;
 align-items: center;

 }

.InputDisabled{
    color:gray;
     border: none !important;
    background-color: white !important;
}
.Input{
    border:none;
    outline:none;
    height: 100%;
    width: 100%;

}
 

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    position: absolute;
     width: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    ;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 200px;
    overflow-y: auto;
    padding-left: 0;
    width:236px;
    z-index: 999;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: white;
}

.suggestions li {
    padding: 0.5rem;
    background-color: white;
    color: black;
    cursor: pointer;
    font-size: 13px;
    width: 100%;

}

.suggestion_active,
.suggestions li:hover {
    background-color: #f2f2f2;
    color: black;
    cursor: pointer;
    font-size: 13px;
}

