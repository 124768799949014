.UserMgtUpdateFormContainer{
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 5px;
}
@media screen and (max-width: 768px) {
    .UserMgtUpdateFormContainer{
        padding: 5px 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 5px;
    }
  }

.UserMgtUpdateFormContainerOne{
    flex:2;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
    color: black !important;
    opacity: 1 !important;
    -webkit-text-fill-color: black !important;
    -webkit-tap-highlight-color: black !important;
}

.MuiInputBase-input{
    -webkit-text-fill-color: black !important;
    -webkit-tap-highlight-color: black !important;
    color: black !important;
    opacity: 1 !important;
} 

.Mui-disabled{
    color: black !important;
    opacity: 1 !important;
    -webkit-text-fill-color: black !important;
    -webkit-tap-highlight-color: black !important;
}

/* }  css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{ */

.MuiOutlinedInput-input {
    color: black !important;
    opacity: 1 !important; 
    -webkit-text-fill-color: black !important;
    -webkit-tap-highlight-color: black !important;

}
.MuiInputBase-inputSizeSmall{
    color: black !important;
    -webkit-text-fill-color: black !important;
    opacity: 1 !important;  
    -webkit-tap-highlight-color: black !important;
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
    color: black !important;
    opacity: 1 !important; 
    -webkit-text-fill-color: black !important;
    -webkit-tap-highlight-color: black !important;
}

.UserMgtUpdateFormContainerSectionOne{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    padding: 10px 20px;
}

.UserMgtUpdateFormContainerSectionOneM{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
}

.UserMgtUpdateFormSectionOneTitleContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.UserMgtUpdateFormSectionOneTitle{
    font-weight: 600;
    font-size: 26px;
    color:#EF6C1A;
}

.UserMgtUpdateFormSectionOneEditContainer{
    /* -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75); */
    background-color: white;
    border-radius: 100%;
    padding: 10px;
}

.UserMgtUpdateFormSectionOneEditIcon{
    width: 25px;
    height:25px
}

.UserMgtUpdateFormContainerSectionTwo{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
}
.UserMgtUpdateFormTwoSubSectionOne{
    margin-top: 20px;
    flex:1;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    gap:15px;
}

.UserMgtUpdateFormTwoSubSectionOneList{
    display: flex;
    flex-direction: row;
    gap:10px;
}
@media screen and (max-width: 468px) {
    .UserMgtUpdateFormTwoSubSectionOneList{
        display: block;
        flex-direction: row;
        gap:10px;
    }
}
.UserMgtUpdateFormTwoSubSectionOneListOne{
    flex:1;
    display: flex;

}
.UserMgtUpdateFormTwoSubSectionOneListTwo{
    flex:3;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.UserMgtUpdateFormTwoSubSectionOneListLabel{
    font-weight: 600;
    font-size: 14px;
}
.UserMgtUpdateFormTwoSubSectionOneListField{
    width:100%;
    color:black !important;
   
}
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 1em !important;
}

.UserMgtUpdateFormTwoSubSectionTwo{
    margin-top: 20px;
    flex:1;
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    gap:15px;
}

.UserMgtUpdateFormContainerSectionThree{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap:15px;
    justify-content: center;
    align-items: center;
}

.UserMgtUpdateFormContainerTwo{
    flex:0.8;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding:2px;
}

.UserMgtUpdateFormContainerTwoProfileContainer{
   width: 120px;
   height:120px;
   background-color: white;
   border-radius: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}
.UserMgtUpdateFormContainerTwoProfile{
    width: 105px;
    height:105px;
    object-fit: cover;
    border-radius: 100%;
}

.UserMgtUpdateFormContainerTwoFirmContainer{
  width: 100%;
  height: 160px;
}

img{
    width: 100%;
    height:100%;
    object-fit: cover;
}

.UserMgtUpdateFormContainerTwoListContainer{
    display: flex;
    width:100%;
    flex-direction: column;
}

.UserMgtUpdateFormContainerTwoList{
    display: flex;
    width:100%;
    flex-direction: row; 
    margin-top: 10px;
}

.UserMgtUpdateFormContainerTwoListOne{
    flex:1;
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
    justify-content: flex-start;
    
}

.UserMgtUpdateFormContainerTwoListTwo{
    flex:1.7;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: center;
    text-align: start;
}

.UserMgtUpdateFormContainerTwoListOneIconContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #EF6C1A;
    margin-left: 18px;
    color:white;
    padding:5px;
    border-radius: 100%;
}

.UserMgtUpdateFormContainerTwoListOneIcon{
    width: 20px;
    height:20px;
}

.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel{
    color:red;
    font-size: 12px;
}