.applyAllButton{
    margin-right: 10px;
    color: #EF6C1A !important;
    border: 1px solid #EF6C1A !important;
    text-transform: none;
    font-weight: 700 !important;
    background-color: #fff !important;
    border-radius: 0px !important;
    padding: 20px !important;
    margin-top: 10px !important;
}
.clearButton{
    color: #EF6C1A !important;
    border: none !important;
    font-weight: 700 !important;
    text-transform: none;
    background-color: #fff !important;
    margin-top: 10px !important;
}