.Container {
    -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    background-color: white;
    padding: 20px 10px;
    border-radius: 5px;
}

.TopContainer {
    display: flex;
    flex-direction: row;
}

.List {
    flex: 1;
    display: flex;
    flex-direction: row;

}

.AddList {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BulkList {

    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 15px;
    gap: 10px;


}

.BulkSubList {

    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.TitleContainer {
    flex: 0.2;
    padding: 5px;
}

.TitleContainer>label {
    font-weight: 600;
}

.TextContainer {
    flex: 1;
}

.SelecText {
    font-size: 10px;
}

.BulkInput {
    display: none;
}

.BulkUploadButton {
    color: white;
    background-color: #731D3A !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
}

.BulkTitle {
    font-weight: 535;
}

.UploadContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.fileContainer {
    background-color: #ccf5ff;
    padding: 5px 10px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    justify-content: space-between;
}

.fileButton {
    border-radius: 100%;
    background: white;
    border: none;
    margin-left: 10px;
    cursor: pointer;
}

.fileButtonIcon {
    width: 15px !important;
    height: 20px !important;
}

.Route{
    display: flex;
    align-items: center;
    margin-top: 8px;
    color:rgb(128, 128, 128);
}