/* canvas{
    min-height: 500px;
    height: 500px;
    max-height:500px ;
} */

.myComponent canvas{
    min-height: 500px;
    height: 500px;
    max-height:500px ;
}