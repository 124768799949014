.RouteMgtContainer{
    /* -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75); */
    display: flex;
    width:95%;
    flex-direction: column;
    gap:10px;
    
}

.RouteMgtCarousel{
    margin-left: 4px;
    background-color: white;
    display: flex;
    flex-direction: row;
    min-height:50px;
    justify-content: center;
    align-items: center; 
    cursor: pointer;
    margin-right: 4px;
    /* -webkit-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.75); */
    gap:10px;
    border-radius: 5px;
    z-index: 999;
}

.RouteMgtCarouselLabelWrapperOne{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    /* width:100%; */
    border-bottom: 3px solid #EF6C1A;
    cursor: pointer;
    margin-left: 10px;
    
}
.RouteMgtCarouselLabelWrapperTwo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    /* width:80%; */
    cursor: pointer;
    transition: 0.3s ease-in-out;
 
}
.RouteMgtCarouselLabelOne{
    color:#EF6C1A;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    font-size: 18px;

  }



.RouteMgtCarouselLabelTwo{
    color:black;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    transition: 0.3s ease-in-out;
}