.container{
    margin: 0;
    padding: 60px 7rem;
    background-color: var(--color-footer);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap:40px;
    background-color: #002e5b;
    color: #f6ce4b;
}

.section{
    flex:2;
    display: flex;
    flex-direction: column;
    gap: 8px;
  
}

.sectionTwo{
    flex:1;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.ContactTitleContainer{
    
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.ContactTitle{
   color: var(--color-yellow);
   font-size: 18px;
   font-weight: bold;
}

.ContactAddressContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
}
.ContactAddressContainer label{
    color: #fff;
}

.ContactAddress{
    color: var(--color-white);
    word-break:keep-all;
    font-size: 14px;
    text-align: justify;
    /* word-wrap:break-word; */
}

.NumberContainer{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.NumberTitle{
    color: var(--color-white);
    font-size: 14px;
    color: #fff;

}

.List{
    color: var(--color-white);
    font-size: 14px;
    color: #fff;
    
}

.List:hover{
    cursor: pointer;
    
}

.ListContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
}

.socialContainer{
    display: flex;
    flex-direction: row;
    gap:20px;
    cursor: pointer;
}

.IconContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--color-yellow);
    padding: 7px 9px;
    border-radius: 5px;
}

.Icon{
    color: var(--color-white);
    font-size: 20px;
}

@media (min-width:300px) and (max-width:650px){

    .ContactTitle{
        font-size: 14px;
    }
    .container{
      flex-direction: column;
      padding: 1rem 3rem;
      justify-content: center;
    }

    .ListContainer,.ContactTitleContainer,.ContactAddressContainer,.ContactAddressContainer{
        justify-content: center;
    }

    .NumberContainer,.ListContainer{
        align-items: center;
    }

    .socialContainer{
        justify-content: center;
    }
}

@media (min-width:650px) and (max-width:768px){
    .container{
        padding: 1rem 1.5rem;
      }

      .ContactTitle{
          font-size: 14px;
      }

      .ContactAddress,.NumberTitle,.List{
          font-size: 12px;
      }

      .Icon{
          font-size: 14px;
      }
}

@media (min-width:768px) and (max-width:992px){

    .container{
        padding: 1rem 2rem;
      }
}